import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Dive into product configurations, tweaking and applying them within your project environment.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Creating Studies</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Opening Studies</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Applying Products</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Configuring Products</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Optimisation</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Using the 3D Viewer</AnchorLink>
    </AnchorLinks>
    <p>{`The configurator allows you to create and run studies on IFC files uploaded to a project.`}</p>
    <p>{`The first step for a new user on the journey to using the configurator and applying Products into Activities is to create a Study `}<strong parentName="p">{`. Studies are required before a user can continue with these activities.`}</strong></p>
    <h2>{`Overview`}</h2>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/79_configurator-map.png",
      "alt": "79_configurator-map.png"
    }}></img>
    <ol>
      <li parentName="ol">{`Your project information`}</li>
      <li parentName="ol">{`Product Manager - where you select a Supply Chain and then choose products from it for Apply.`}</li>
      <li parentName="ol">{`Viewer - View your model and applied products, toggle viewer settings and switch between different loaded models.`}</li>
      <li parentName="ol">{`Output Panel - Here you can see the results of Apply. Simple metrics show you the impact each product has when placed within your design.`}</li>
      <li parentName="ol">{`Saves - Studies allow you to set up design files and products for testing. When you are happy with the outcomes and wish to continue your design work, Save Result to lock in those choices for use and future review.`}</li>
    </ol>
    <h2>{`Creating Studies`}</h2>
    <p>{`To create a Study:`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the Projects Configurator.`}
        <ol parentName="li">
          <li parentName="ol">{`Go to your Projects tab.`}</li>
          <li parentName="ol">{`Click on the relevant project.`}</li>
          <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`Configurator`}</inlineCode>{` button.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`If this is the first time you are using the Configurator, you will be prompted to create your first study.`}</li>
      <li parentName="ol">{`Enter a `}<strong parentName="li">{`Name`}</strong>{` and `}<strong parentName="li">{`Description`}</strong>{` for your study.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/80_configurator-new-study.png",
          "alt": "80_configurator-new-study.png"
        }}></img></li>
      <li parentName="ol">{`For users with existing studies looking to create a new one, select the `}<inlineCode parentName="li">{`Studies`}</inlineCode>{` button.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/81_configurator-new-study-button.png",
          "alt": "81_configurator-new-study-button.png"
        }}></img></li>
      <li parentName="ol">{`The `}<strong parentName="li">{`Manage Studies`}</strong>{` window will open.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Create New Study`}</inlineCode>{`.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/82_configurator-create-new-study-from-study-manager.png",
          "alt": "82_configurator-create-new-study-from-study-manager.png"
        }}></img></li>
      <li parentName="ol">{`Enter a `}<strong parentName="li">{`Name`}</strong>{` and `}<strong parentName="li">{`Description`}</strong>{` for your study.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/83_configurator-new-study.png",
          "alt": "83_configurator-new-study.png"
        }}></img></li>
    </ol>
    <h2>{`Opening Studies`}</h2>
    <p>{`To open a different study to the currently selected study:`}</p>
    <ol>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`Studies`}</inlineCode>{` button.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/84_configurator-new-study-button-copy.png",
          "alt": "84_configurator-new-study-button-copy.png"
        }}></img></li>
      <li parentName="ol">{`Click on the study you want to open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/85_configurator-swapping-between-studies.png",
          "alt": "85_configurator-swapping-between-studies.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Open Study`}</inlineCode>{`.`}</li>
    </ol>
    <h2>{`Applying Products`}</h2>
    <p>{`To apply products to a model inside your Study.`}</p>
    <ol>
      <li parentName="ol">{`Click the `}<inlineCode parentName="li">{`Select IFC File`}</inlineCode>{` button.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/86_configurator-upload-file.png",
          "alt": "86_configurator-upload-file.png"
        }}></img></li>
      <li parentName="ol">{`Select the IFC file for the product application from your uploaded files.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Select`}</inlineCode>{`.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/87_configurator-select-file-to-upload.png",
          "alt": "87_configurator-select-file-to-upload.png"
        }}></img></li>
      <li parentName="ol">{`Select a `}<inlineCode parentName="li">{`Supply Chain`}</inlineCode>{` for product application.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/88_configurator-select-supply-chain.png",
          "alt": "88_configurator-select-supply-chain.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Select Product`}</inlineCode>{`.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/89_configurator-products-buttons.png",
          "alt": "89_configurator-products-buttons.png"
        }}></img></li>
      <li parentName="ol">{`Select a product for application to your model.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/90_configurator-selecting-product-to-apply.png",
          "alt": "90_configurator-selecting-product-to-apply.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Select`}</inlineCode>{`.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/91_configurator-select-product.png",
          "alt": "91_configurator-select-product.png"
        }}></img></li>
      <li parentName="ol">{`KOPE allows you to select and apply multiple products at once. Select the products you want KOPE to Apply to your model.`}</li>
      <li parentName="ol">{`Once all desired products are selected, click `}<inlineCode parentName="li">{`Apply Products`}</inlineCode>{`.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/92_configurator-apply-products.png",
          "alt": "92_configurator-apply-products.png"
        }}></img></li>
      <li parentName="ol">{`After a brief loading time, the products will be applied to the model.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/93_configurator-apply.png",
          "alt": "93_configurator-apply.png"
        }}></img></li>
    </ol>
    <h2>{`Configuring Products`}</h2>
    <p>{`Products within KOPE are configurable to your needs, how configurable is dependent on the product and its unique parameter ranges.`}</p>
    <p>{`To configure the products that are being applied to your model:`}</p>
    <ol>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`Configure`}</inlineCode>{` button next to the product you want to customise.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/94_product-configure-configure-products.png",
          "alt": "94_product-configure-configure-products.png"
        }}></img></li>
      <li parentName="ol">{`The Configure window will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/95_configurator-configure-window.png",
          "alt": "95_configurator-configure-window.png"
        }}></img></li>
      <li parentName="ol">{`Each product will have a unique set of configurable data grouping tabs.`}</li>
      <li parentName="ol">{`Adjust the variables in the tab as desired to flex the KOPE Configurator outcome. `}</li>
      <li parentName="ol">{`Once changes have been made, click `}<inlineCode parentName="li">{`Save`}</inlineCode>{` to preserve the settings.`}</li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Apply Products`}</inlineCode>{` to implement the new settings on your model.`}</li>
    </ol>
    <InlineNotification mdxType="InlineNotification">
Each selected product can be configured independantly.
    </InlineNotification>
    <h3>{`Output Panel`}</h3>
    <p>{`The `}<strong parentName="p">{`Output`}</strong>{` panel provides a statistics breakdown of the products applied to your model. This is located on the right side of the screen and provides quantities, costing and area calculations for each applied product.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/96_product-configure-output-panel.png",
      "alt": "96_product-configure-output-panel.png"
    }}></img>
    <h3>{`Dashboard`}</h3>
    <p>{`The dashboard provides access to Metrics and Tables for each product applied to your model.
`}<img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/97_product-configure-dashboard.png",
        "alt": "97_product-configure-dashboard.png"
      }}></img></p>
    <p>{`To access the dashboard for a product, click on the `}<inlineCode parentName="p">{`Dashboard`}</inlineCode>{` button in the output summary for that product.
`}<img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/98_configurator-dashboard-button.png",
        "alt": "98_configurator-dashboard-button.png"
      }}></img></p>
    <h3>{`Saving Results`}</h3>
    <p>{`To save the results for further analysis and future reference click the `}<inlineCode parentName="p">{`Save Result`}</inlineCode>{` button in the top right.`}<br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/99_configurator-saving-results.png",
        "alt": "99_configurator-saving-results.png"
      }}></img></p>
    <h2>{`Optimisation`}</h2>
    <p>{`With KOPE, you can go further than placing products based on your choices. You can utilize the KOPE Optimization Engine to find optimal results against a wide array of goals and project targets.`}</p>
    <h3>{`Optimising Products`}</h3>
    <p>{`To use the optimization engine:`}</p>
    <ol>
      <li parentName="ol">{`Select a `}<inlineCode parentName="li">{`Supply Chain`}</inlineCode>{` for product application.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/100_configurator-select-supply-chain.png",
          "alt": "100_configurator-select-supply-chain.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Select Product`}</inlineCode>{`.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/89_configurator-products-buttons.png",
          "alt": "89_configurator-products-buttons.png"
        }}></img></li>
      <li parentName="ol">{`Select a product for application to your model.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/101_configurator-selecting-product-to-apply.png",
          "alt": "101_configurator-selecting-product-to-apply.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Select`}</inlineCode>{`.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/102_configurator-select-product.png",
          "alt": "102_configurator-select-product.png"
        }}></img></li>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`⫶`}</inlineCode>{` icon to the right of the Product you wish to optimise.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/103_configurator-optimise-3-dots.png",
          "alt": "103_configurator-optimise-3-dots.png"
        }}></img></li>
      <li parentName="ol">{`In the opened menu, click `}<inlineCode parentName="li">{`Optimise`}</inlineCode>{`.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/104_configurator-optimise-button.png",
          "alt": "104_configurator-optimise-button.png"
        }}></img></li>
      <li parentName="ol">{`The Optimize window will open.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/105_configurator-optimise-selection.png",
          "alt": "105_configurator-optimise-selection.png"
        }}></img></li>
      <li parentName="ol">{`Define the Optimization settings for the product.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/106_configurator-optimise-layout.png",
          "alt": "106_configurator-optimise-layout.png"
        }}></img>
        <pre parentName="li"><code parentName="pre" {...{}}>{`1. Number of options, this controls how many options are generated.
2. Variables, select variables of the product that you are happy to allow KOPE to flex.
3. Objectives, decide what outcomes you are aiming for.
`}</code></pre>
      </li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Save`}</inlineCode>{` when finished.`}</li>
      <li parentName="ol">{`The Blue Optimised icon lets you know that the product is ready for optimisation.
`}<img parentName="li" {...{
          "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/107_configurator-optimise-blue-dot.png",
          "alt": "107_configurator-optimise-blue-dot.png"
        }}></img></li>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Apply Products`}</inlineCode>{` to run optimisation on your model.`}</li>
    </ol>
    <h3>{`Viewing Optimization Results`}</h3>
    <p>{`To view the optimised options generated by KOPE, click on the Option button on the Output Panel.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/108_configurator-optimise-dashboard-button.png",
      "alt": "108_configurator-optimise-dashboard-button.png"
    }}></img>
    <p>{`In this window, you can view all the options generated by the KOPE Optimization Engine.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/109_optimize-optimized-results.png",
      "alt": "109_optimize-optimized-results.png"
    }}></img>
    <p>{`Using the `}<inlineCode parentName="p">{`Order by`}</inlineCode>{` drop-down menu, you can order the results by your chosen property.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/110_configurator-optimise-order-by.png",
      "alt": "110_configurator-optimise-order-by.png"
    }}></img>
    <p>{`Using the `}<inlineCode parentName="p">{`Filters`}</inlineCode>{` drop-down menu, you can filter the results by your chosen property.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/111_configurator-optimise-filter-by.png",
      "alt": "111_configurator-optimise-filter-by.png"
    }}></img>
    <p>{`When you are happy with an option, you can make that your baseline by clicking `}<inlineCode parentName="p">{`Choose an option`}</inlineCode>{`.`}</p>
    <h2>{`Using the 3D Viewer`}</h2>
    <p>{`The KOPE viewer matches many industry standards and has similar features to most viewers. Below is an overview of controls to manage how you interact and view your models.`}</p>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/112_viewer-map.png",
      "alt": "112_viewer-map.png"
    }}></img>
    <ol>
      <li parentName="ol">{`Model Selector - allowing you to change the base model you are applying products to.`}</li>
      <li parentName="ol">{`Model Visibility - Toggles to control visibility for each model, including base file and model per product applied.`}</li>
      <li parentName="ol">{`Viewer Space - Models will show here including view-box allowing you to move around fixed orthogonal views.`}</li>
      <li parentName="ol">{`View Control Bar - Series of controls for viewer behaviour. See below for further information.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/113_viewer-toolbar.png",
      "alt": "113_viewer-toolbar.png"
    }}></img>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Camera positioning around content, including toggle for perspective/ortho camera.`}</strong></p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/114_3d-viewer-orbit.png",
            "alt": "114_3d-viewer-orbit.png"
          }}></img>{` Orbit - Rotate the view around the centre of focus.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/115_3d-viewer-pan.png",
            "alt": "115_3d-viewer-pan.png"
          }}></img>{` Pan - Shift the view horizontally and vertically.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/116_3d-viewer-zoom.png",
            "alt": "116_3d-viewer-zoom.png"
          }}></img>{` Zoom - Adjust the magnification level of the view.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/117_3d-viewer-toggle-camera-type.png",
            "alt": "117_3d-viewer-toggle-camera-type.png"
          }}></img>{` Toggle Camera Type - Switch between different camera perspectives.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/118_3d-viewer-fit-to-view.png",
            "alt": "118_3d-viewer-fit-to-view.png"
          }}></img>{` Fit to View - Adjust the view to display the entire model within the window.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`In-depth reviewing by sectioning and measuring elements.`}</strong></p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/119_3d-viewer-measure.png",
            "alt": "119_3d-viewer-measure.png"
          }}></img>{` Measure - Enable measurement tools for distances.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/120_3d-viewer-section-analysis.png",
            "alt": "120_3d-viewer-section-analysis.png"
          }}></img>{` Section Analysis - Cut through the model to reveal internal details.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Brings up the element information for selected items.`}</strong></p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/121_3d-viewer-view-element-info.png",
            "alt": "121_3d-viewer-view-element-info.png"
          }}></img>{` View Element Info - Display information about selected elements in the model.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Depper controls for exploring element and model data and broader array of viewer settings.`}</strong></p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/122_3d-viewer-model-elements-browser.png",
            "alt": "122_3d-viewer-model-elements-browser.png"
          }}></img>{` Model Elements Browser - Open a browser to navigate and manage model elements.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/123_3d-viewer-showhide-models.png",
            "alt": "123_3d-viewer-showhide-models.png"
          }}></img>{` Show / Hide Models - Toggle visibility of specific model components.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/124_3d-viewer-scene-settings.png",
            "alt": "124_3d-viewer-scene-settings.png"
          }}></img>{` Scene Settings - Access and modify global settings for the 3D scene.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/125_3d-viewer-full-screen.png",
            "alt": "125_3d-viewer-full-screen.png"
          }}></img>{` Full Screen - Expand the viewer to full-screen mode for immersive viewing.`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      